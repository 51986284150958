<script>

  import AlertCard from './components/AlertCard.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';
  import axios from 'axios';

  import {getCookieByName, setErrorEventData} from '../../funcs';
  import {onMount} from 'svelte';

  export let appName = null;
  export let id = null;

  const token = getCookieByName(`${'production'}_token`);

  let fetchNotificationsError = false;
  let notificationsCollection = [];
  let userHasDevices = false;
  let selectedNotification;
  let userPhoneNumber;
  let loading = true;

  const headers = {
    headers: {
      Authorization: token
    }
  };

  const userPhoneNumberFetch = async () => {
    try {
      const {data} = await axios.get(`https://smartcities-api.cox2m.com/users/`, headers);
      userHasDevices = data.devices && data.devices.length;
      userPhoneNumber = data.phoneNumber;
    } catch (error) {
      setErrorEventData(window.dispatchEvent, error.response, 'get-user');
    }
  };

  const getApplicationId = async () => {
    if (appName && !id) {
      try {
        const {data} = await axios.get(
          'https://smartcities-api.cox2m.com/apps?limit=100&offset=0',
          headers
        );
        id = data.apps.find(
          app => app.name === appName || app.slug === appName.toLowerCase()
        ).appId;
        handleApplicationIdChange(id);
      } catch (error) {
        setErrorEventData(window.dispatchEvent, error.response, 'get-apps');
        fetchNotificationsError = true;
      }
    } else {
      handleApplicationIdChange(id);
    }
  };

  onMount(() => {
    getApplicationId();
  });

  $: handleApplicationIdChange(id);

  const handleApplicationIdChange = async id => {
    if (id) {
      loading = true;
      selectedNotification = null;

      try {
        const allSubscriptionsResponse = await axios.get(
          'https://smartcities-api.cox2m.com/notifications/settings',
          headers
        );
        const eventTypesResponse = await axios.get(
          `https://smartcities-api.cox2m.com/notifications/${id}/metrics`,
          headers
        );

        const userSubscriptions = allSubscriptionsResponse.data.filter(
          notification => notification.clientId === token.substring(0, 8)
        );

        notificationsCollection = await eventTypesResponse.data.reduce(
          async (accumulatorPromise, current, index) => {
            let accumulator = await accumulatorPromise;
            const additionalFilters = await axios.get(
              `https://smartcities-api.cox2m.com/events/filters/${current.id}`,
              headers
            );
            accumulator[index] = {
              ...current,
              additionalFilters: additionalFilters.data,
              subscriptions: userSubscriptions.filter(
                currentSubscription =>
                  currentSubscription.eventDefinition.id === current.id
              ),
              subscribed:
                userSubscriptions.findIndex(
                  currentSubscription =>
                    currentSubscription.eventDefinition.id === current.id
                ) !== -1
            };
            return accumulator;
          },
          []
        );

        loading = false;
      } catch (error) {
        setErrorEventData(
          window.dispatchEvent,
          error.response,
          'get-all-notifications-configuration and get-notifications-metrics-by-app-id'
        );
        fetchNotificationsError = true;
        loading = false;
      }
    } else {
      getApplicationId();
    }
  };

  userPhoneNumberFetch();
</script>

<style>
  .loading-container,
  .error-container {
    width: 100%;
    height: 80vh;
    margin-top: var(--cox2m-spacing-8-units);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error-message {
    color: var(--cox2m-clr-neutral-700);
  }
  .app-name-container {
    background: var(--cox2m-clr-neutral-white);
    margin: -32px -32px 12px -32px;
    padding: var(--cox2m-spacing-10-units) var(--cox2m-spacing-8-units);
    color: var(--cox2m-clr-neutral-black);
    font-weight: var(--cox2m-fw-bold);
  }
  h1 {
    margin: 0;
  }
</style>

{#if loading && !fetchNotificationsError}
  <div class="loading-container">
    <div>
      <LoadingSpinner />
    </div>
  </div>
{:else if !fetchNotificationsError}
  <div class="app-name-container">
    <h1>{appName}</h1>
  </div>
  {#if notificationsCollection.length}
    <div
      data-testid="notification-events-container"
      id="notification-events-container">
      {#each notificationsCollection as notification}
        <AlertCard
          {notification}
          {userPhoneNumber}
          {userHasDevices}
          on:creation-success={() => {handleApplicationIdChange()}}
          />
      {/each}
    </div>
  {:else}
    <div class="error-container">
      <div>
        <h2
          class="error-message"
          id="empty-notifications-message"
          data-testid="empty-notifications-message">
          There are no event notifications for this application yet.
        </h2>
      </div>
    </div>
  {/if}
{:else}
  <div class="error-container">
    <div>
      <h2
        class="error-message"
        id="notifications-fetching-error-message"
        data-testid="notifications-fetching-error-message">
        We are sorry! please refresh the page or try again later
      </h2>
    </div>
  </div>
{/if}
