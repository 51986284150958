<script>
  import Button from '../../Button.svelte';
  import Icon from '../../Icon.svelte';
  import axios from 'axios';

  import {ALERT_SEVERITY_INDICATORS} from '../../../constants';
  import {setErrorEventData} from '../../../funcs';
  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  export let smartCitiesUrl;
  export let alertEvent = null;
  export let token;

  const headers = {
    headers: {
      Authorization: token
    }
  };

  const updateNotificationToReadStatus = () => {
    axios
      .put(
        `https://smartcities-api.cox2m.com/notifications/${alertEvent.id}`,
        {
          token,
          status: 'read'
        },
        headers
      )
      .then(() => {
        dispatch('updated-alert-status', alertEvent.id);
        if(smartCitiesUrl){
          window.location.href = `https://smartcities.cox2m.com/#/notifications`;
          // window.location.href = `https://smartcities.cox2m.com/#/alerts/${appSlug}`;//later this should include /${alertEvent.id}
        }else{
          window.location.href = `https://smartcities.cox2m.com/#/notifications`;
          // window.location.href = `/#/alerts`;//later this should include /${alertEvent.id}
        }
      })
      .catch(err => {
        setErrorEventData(window.dispatchEvent, err, 'put-notifications-id');
      });
  };

  const fillWithZeros = text => {
    return text.toString().padStart(2, '0');
  };

  const formatDateLabel = date => {
    const d = new Date(date);
    return `${fillWithZeros(d.getDay())}.${fillWithZeros(
      d.getMonth()
    )}.${d.getFullYear()} ${fillWithZeros(d.getHours() % 2)}:${fillWithZeros(
      d.getMinutes()
    )} ${d.getHours() > 12 ? 'AM' : 'PM'}`;
  };

  $: dateLabel = formatDateLabel(alertEvent.createdAt);
</script>

<style>
  .alert-preview-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: var(--cox2m-spacing-4-units);
    border-bottom: 1px solid var(--cox2m-clr-neutral-400);
  }

  .title-container {
    display: flex;
    gap: var(--cox2m-spacing-2-units);

    align-items: center;
  }

  .title-info-container {
    display: flex;
    flex-direction: column;
  }

  .alert-date {
    color: var(--cox2m-clr-neutral-700);
    font-size: var(--cox2m-fs-1300);
    letter-spacing: 1.5px;
  }

  .alert-name {
    font-size: var(--cox2m-fs-700);
    font-weight: var(--coxm2-fw-bold);
    color: var(--cox2m-clr-neutral-black);
  }
</style>

<div class="alert-preview-container">
  <div class="title-container">
    <Icon
      icon={ALERT_SEVERITY_INDICATORS[alertEvent.severity].icon}
      color={ALERT_SEVERITY_INDICATORS[alertEvent.severity].iconColor}
      size="var(--cox2m-spacing-6-units)" />

    <div class="title-info-container">
      <span class="alert-date">{dateLabel}</span>
      <span class="alert-name">{alertEvent.summary}</span>
    </div>
  </div>

  <Button
    customClass="font-weight-bold"
    width="auto"
    height="var(--cox2m-spacing-8-units)"
    id="alert-details"
    backgroundColor="var(--cox2m-clr-neutral-white)"
    color="var(--cox2m-clr-neutral-black)"
    borderColor="var(--cox2m-clr-brand-400)"
    on:click={() => {
      updateNotificationToReadStatus();
    }}
    text="Details" />
</div>
