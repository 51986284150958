<script>
  import clickOutside from "../../../svelte-actions/clickOutside";
  import Icon from "../../../components/Icon.svelte";
  import axios from "axios";

  import {getCookieByName, setErrorEventData} from "../../../funcs";
  import { capitalize } from "../../../funcs";
  import { link } from "svelte-spa-router";
  import { fly } from "svelte/transition";
  import {user as userStore} from '../../../stores';

  export let user;

  const token = getCookieByName(`${"production"}_token`);
  let expanded = false;

  const viewProfile = () => {
    window.location.href = "https://smartcities.cox2m.com/#/account/profile/edit";
  };

  const logoutUser = async () => {
    axios
      .delete("https://smartcities-api.cox2m.com/users/sessions", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          authorization: token,
        },
      })
      .then(() => {
        if (document.location.hostname === "localhost") {
          document.cookie = "token=;";
        } else {
          document.cookie = `token=;path=/;domain=cox2m.com`;
        }
        userStore.logout();
        window.location.href = "https://smartcities.cox2m.com/#/logout";
      })
      .catch((err) => {
        console.error(err);
        setErrorEventData(window.dispatchEvent, err.response, "logout");
      }).finally(() => {
        document.cookie = `${'production'}_cox2mlatesturl=;path=/;domain=cox2m.com`;
      });
  };
</script>

<div
  use:clickOutside={() => (expanded = false)}
  on:click={() => (expanded = !expanded)}
  class="flex-shrink-0 user-actions-container cursor-pointer align-items-center justify-content-between position-relative"
>
  <span class="user-name-container font-weight-bold"
    >{user && user.givenName ? capitalize(user.givenName) : ""}
    {user && user.familyName
      ? user.familyName[0].toUpperCase() + "."
      : ""}</span
  >
  <Icon
    icon={expanded ? "chevron-up" : "chevron-right"}
    color="var(--cox2m-clr-brand-600)"
    size="var(--cox2m-spacing-5-units)"
  />

  {#if expanded}
    <div
      in:fly={{
        y: 50,
        duration: 500,
      }}
      out:fly={{
        y: 50,
        duration: 500,
      }}
      id="user-actions-options-container"
      data-testid="user-actions-options-container"
      class="position-absolute options-container w-100 py-2"
    >
      <div
        class="option d-flex align-items-center"
        on:click|preventDefault={viewProfile}
      >
        <a id="edit-profile-menu-button" href="/account/profile/edit" use:link>
          View Profile
        </a>
      </div>
      <div
        class="option d-flex align-items-center"
        on:click|preventDefault={logoutUser}
      >
        <a id="logout-menu-button" class="logout" href="/"> Logout </a>
      </div>
    </div>
  {/if}
</div>

<style>
  .user-actions-container {
    display: flex;
    margin-left: 1rem;
  }

  .user-actions-container .user-name-container {
    color: var(--cox2m-clr-brand-600);
    font-size: var(--cox2m-fs-1000);
    min-width: var(--cox2m-spacing-30-units);
    padding: var(--cox2m-spacing-1-units);
  }
  .user-actions-container::before {
    border-bottom: var(--cox2m-brd-w-2) solid var(--cox2m-clr-brand-300);
    position: absolute;
    content: "";
    width: 80%;
    bottom: 0;
  }

  .options-container {
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-400);
    box-shadow: var(--cox2m-shadow-3-dp-dir) var(--cox2m-shadow-3-dp-clr);
    background-color: var(--cox2m-clr-neutral-white);
    width: auto !important;
    top: calc(100% + var(--cox2m-spacing-3-units));
    border-radius: 0.5rem;
    overflow-y: auto;
    min-width: 100%;
    left: calc(var(--cox2m-spacing-3-units) * (-1));
  }
  .option {
    cursor: pointer;
    color: var(--cox2m-clr-brand-600);
    font-weight: var(--cox2m-fw-bold);
    padding: var(--cox2m-spacing-2-units);
    font-size: var(--cox2m-fs-1000);
  }
  .option:hover {
    background-color: var(--cox2m-clr-brand-200);
  }

  @media only screen and (max-width: 767px) {
    .user-actions-container {
      display: none;
    }
  }
</style>
