<script>
  import NotificationItem from "./NotificationItem.svelte";
  import axios from "axios";

  import { getCookieByName } from "../../../funcs";

  export let notifications = [];

  function handleNotificationsUpdate(notification) {
    notifications = notifications.map((data) =>
      data.id === notification.id ? notification : data
    );
  }

  function handleViewAllNotifications() {
    window.location.href = "https://smartcities.cox2m.com/#/notifications";
  }

  async function handleClearAllNotifications() {
    const TOKEN = getCookieByName(`${"production"}_token`);

    axios
      .delete("https://smartcities-api.cox2m.com/notifications", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: TOKEN,
        },
      })
      .then((res) => {
        if (res.status === 204) {
          notifications = [];
        }
      })
      .catch((err) => console.error(err));
  }
</script>

<div
  id="notifications-dropdown"
  data-testid="notifications-dropdown"
  class="notification-button dropdown-menu notifications-dropdown dropdown-menu-right
  dropdown-responsive"
>
  <header>
    <h6 class="m-0">Notifications</h6>
  </header>
  <div class="list-container">
    {#each notifications as notification}
      {#if notification.status === "created"}
        <NotificationItem {notification} {handleNotificationsUpdate} />
      {/if}
    {/each}
  </div>
  <div class="anchor-container fs-1000">
    <button class="btn btn-link" on:click={handleViewAllNotifications}>
      View All Notifications
    </button>
    <button
      data-testid="clear-all-notifications"
      id="clear-all-notifications"
      class="btn btn-link"
      on:click={handleClearAllNotifications}
    >
      Clear All Notifications
    </button>
  </div>
</div>

<style>
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
  .dropdown-menu {
    min-width: 326px;
    padding: 0px;
    cursor: default;
    max-height: 60vh;
    overflow: auto;
  }

  header {
    height: 50px;
    background-color: var(--cox2m-clr-brand-700);
    color: var(--cox2m-clr-neutral-white);
    display: flex;
    align-items: center;
    padding: 0px 5%;
  }

  .anchor-container {
    align-items: center;
  }

  .list-container {
    height: calc(100% - var(--cox2m-spacing-25-units));
    overflow: auto;
    max-height: 500px;
  }

  .btn {
    width: 100%;
    margin: 0px;
  }

  @media (max-width: 767.98px) {
    .dropdown-responsive {
      overflow: hidden;
      margin: 0;
    }
    .dropdown-menu {
      max-height: unset;
    }
  }
</style>
