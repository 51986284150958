<script>
  import LoadingSpinner from "../../LoadingSpinner.svelte";
  import Icon from "../../Icon.svelte";
  import axios from "axios";

  import { getCookieByName, setErrorEventData } from "../../../funcs";
  import { createEventDispatcher } from "svelte";

  export let onUpdateSuccess = () => {};
  export let additionalFilterSelections;
  export let selectedSnoozeFrequency;
  export let userHasDevices = false;
  export let selectedThreshold;
  export let emailNotifications;
  export let selectedCondition;
  export let textNotifications;
  export let selectedFrequency;
  export let notificationName;
  export let type = "create"; //create | update
  export let alertId = null;
  export let phoneNumber;
  export let onSuccess;
  export let metricId;
  export let id;

  const dispatch = createEventDispatcher();

  let displayError = false;
  let displaySuccess = false;
  let loading = false;

  const headers = {
    headers: {
      Authorization: getCookieByName(`${"production"}_token`),
    },
  };

  const snoozeLabel = (miliseconds) => {
    const minutes = miliseconds / (1000 * 60);
    let label;
    if (minutes < 60) {
      label = minutes + " minutes";
    } else if (minutes === 60) {
      label = 1 + " hour";
    } else if (minutes > 60) {
      label = minutes / 60 + " hours";
    }
    return label;
  };

  const minutesToMili = (minutes) => {
    return 1000 * 60 * minutes;
  };

  const getNotificationVia = () => {
    if (emailNotifications && textNotifications) {
      return ["EMAIL", "SMS"];
    } else if (emailNotifications && !textNotifications) {
      return ["EMAIL"];
    } else if (!emailNotifications && textNotifications) {
      return ["SMS"];
    } else {
      return ["NONE"];
    }
  };
  const hideModal = () => {
    setTimeout(() => {
      if (document.querySelector(".modal-backdrop.fade.show")) {
        document.querySelector(".modal-backdrop.fade.show").classList.value =
          "";
      }
      onSuccess();
      dispatch("alertCreationOrDeletion");
    }, 800);
  };

  const optionsBuilder = () => {
    return {
      frequency: selectedFrequency,
      delay: selectedSnoozeFrequency || minutesToMili(5),
      severity: "alert",
      via: getNotificationVia(),
      eventDefinitionId: metricId,
      condition: selectedCondition || "==",
      threshold: selectedThreshold || 0.0,
      additionalFilters: additionalFilterSelections.filter(
        (filter) => filter.condition && filter.threshold
      ),
    };
  };
  const alertCreationHandler = async () => {
    loading = true;

    const options = optionsBuilder();

    try {
      await axios.post(
        `https://smartcities-api.cox2m.com/notifications/settings`,
        {
          ...options,
        },
        headers
      );
      displaySuccess = true;
      hideModal();
    } catch (error) {
      setErrorEventData(
        window.dispatchEvent,
        error.response,
        "post-notification-configuration"
      );
      displayError = true;
    }
    loading = false;
  };

  const getConditionLabel = (operator) => {
    switch (operator) {
      case ">":
        return "greater than";
      case "<":
        return "lower than";
      case ">=":
        return "greater than or equal to";
      case "<=":
        return "lower than or equal to";
      case "==":
        return "equal to";
    }
  };

  const getConditionLabelForAdditionalFilters = (operator, type) => {
    if (type === "STRING") {
      switch (operator) {
        case "==":
          return "is equal to";
        case "!=":
          return "is different than";
        case "=~":
          return "contains";
        default:
          return "";
      }
    } else if (type === "NUMBER" || type === "DATE") {
      switch (operator) {
        case "==":
          return "is equal to";
        case "<=":
          return "is minor than";
        case ">=":
          return "is greater than";
        default:
          return "";
      }
    } else if (type === "MULTI_SELECT") {
      switch (operator) {
        case "=~":
          return "is equal to";
        default:
          return "";
      }
    }
  };

  const alertUpdateHandler = async () => {
    loading = true;

    const options = optionsBuilder();
    try {
      await axios.patch(
        `https://smartcities-api.cox2m.com/notifications/settings/${alertId}`,
        options,
        headers
      );
      displaySuccess = true;
      onUpdateSuccess(options);
    } catch (error) {
      setErrorEventData(
        window.dispatchEvent,
        error.response,
        "patch-notification-configuration"
      );
      displayError = true;
    }
    loading = false;
  };

  const alertActionHandler = () => {
    if (type === "create") {
      alertCreationHandler();
    } else {
      alertUpdateHandler();
    }
  };
  const handleClose = () => {
    setTimeout(() => {
      displaySuccess = false;
      displayError = false;
    }, 500);
  };
</script>

<div
  class="modal fade"
  {id}
  data-testid={id}
  tabindex="-1"
  role="dialog"
  aria-labelledby={id + "Label"}
  aria-hidden="true"
  data-backdrop="static"
>
  <div class="modal-dialog backdrop modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" data-testid={id + "Label"} id={id + "Label"}>
          Confirm Action
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          id="confirmation-modal-close-button"
          data-testid="confirmation-modal-close-button"
          disabled={loading}
          on:click={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {#if !loading}
          {#if !displayError && !displaySuccess}
            Are you sure you want to {type} this alert for the
            <strong data-testid="event-name" id="event-name"
              >{notificationName}</strong
            >
            entry with the following options?
            <ul class="list-group mt-3">
              {#if selectedFrequency}
                <li class="list-group-item">
                  Receive alerts:
                  <strong
                    data-testid="confirmation-selected-frequency"
                    id="confirmation-selected-frequency"
                  >
                    {selectedFrequency.toLowerCase()}
                  </strong>
                </li>
              {/if}
              {#if selectedSnoozeFrequency}
                <li class="list-group-item">
                  Snooze between repeated alerts:
                  <strong
                    data-testid="confirmation-selected-snooze"
                    id="confirmation-selected-snooze"
                  >
                    {snoozeLabel(selectedSnoozeFrequency)}
                  </strong>
                </li>
              {/if}
              <li class="list-group-item">
                Email notifications :
                {#if emailNotifications}
                  <Icon
                    icon="check-circle"
                    id="confirmation-email-notifications-icon"
                    size="var(--cox2m-spacing-5-units)"
                    color="var(--cox2m-clr-success-500)"
                    className="btn p-0 m-0"
                  />
                {:else}
                  <Icon
                    icon="cross-circle"
                    id="confirmation-no-email-notifications-icon"
                    size="var(--cox2m-spacing-5-units)"
                    color="var(--cox2m-clr-critical-600)"
                    className="btn p-0 m-0"
                  />
                {/if}
              </li>
              {#if phoneNumber}
                <li class="list-group-item">
                  {userHasDevices ? 'Phone app' : 'SMS'} notifications:
                  {#if textNotifications}
                    <Icon
                      id="confirmation-text-notifications-icon"
                      icon="check-circle"
                      size="var(--cox2m-spacing-5-units)"
                      color="var(--cox2m-clr-success-500)"
                      className="btn p-0 m-0"
                    />
                  {:else}
                    <Icon
                      id="confirmation-no-text-notifications-icon"
                      icon="cross-circle"
                      size="var(--cox2m-spacing-5-units)"
                      color="var(--cox2m-clr-critical-600)"
                      className="btn p-0 m-0"
                    />
                  {/if}
                </li>
              {/if}
              {#if selectedCondition}
                <li class="list-group-item">
                  When:
                  <strong
                    data-testid="confirmation-selected-condition"
                    id="confirmation-selected-condition"
                  >
                    {getConditionLabel(selectedCondition)}
                  </strong>
                </li>
                <li class="list-group-item">
                  Threshold Value:
                  <strong
                    data-testid="confirmation-selected-threshold"
                    id="confirmation-selected-threshold"
                  >
                    {selectedThreshold}
                  </strong>
                </li>
              {/if}
              {#if additionalFilterSelections && additionalFilterSelections.length}
                <li class="list-group-item">
                  <strong
                    data-testid="confirmation-additional-filters"
                    id="confirmation-additional-filters"
                  >
                    Additional Filters
                  </strong>
                </li>
                {#each additionalFilterSelections as additionalFilter}
                  <li class="list-group-item">
                    When {additionalFilter.eventDefinitionFilter.label} :
                    <strong
                      data-testid="confirmation-selected-condition"
                      id="confirmation-selected-condition"
                    >
                      {getConditionLabelForAdditionalFilters(
                        additionalFilter.condition,
                        additionalFilter.eventDefinitionFilter.type
                      )}
                      {additionalFilter.threshold || ""}
                    </strong>
                  </li>
                {/each}
              {/if}
            </ul>
          {:else if displayError}
            <div class="d-flex justify-content-center align-items-center">
              <div>
                <span
                  data-testid="confirmation-error-message"
                  id="confirmation-error-message"
                >
                  We are sorry! there was an error, please try again later or
                  refresh the page
                </span>
              </div>
            </div>
          {:else if displaySuccess}
            <div class="d-flex justify-content-center align-items-center">
              <div>
                <span
                  data-testid="confirmation-success-message"
                  id="confirmation-success-message"
                >
                  The alert was {type}d successfully!
                </span>
              </div>
            </div>
          {/if}
        {:else}
          <div
            class="loading-container d-flex justify-content-center
            align-items-center"
          >
            <div>
              <LoadingSpinner />
            </div>
          </div>
        {/if}
      </div>
      <div class="modal-footer">
        {#if !displayError && !displaySuccess}
          <button
            type="button"
            class="btn btn-secondary"
            data-testid="confirmation-close-button"
            id="confirmation-close-button"
            disabled={loading}
            data-dismiss="modal"
            on:click={handleClose}
          >
            Cancel
          </button>
        {:else}
          <button
            type="button"
            class="btn btn-secondary"
            disabled={loading}
            data-dismiss="modal"
            on:click={handleClose}
          >
            Close
          </button>
        {/if}
        {#if !displaySuccess && !displayError}
          <button
            type="button"
            class="btn btn-primary"
            data-testid="confirmation-send-button"
            id="confirmation-send-button"
            disabled={displaySuccess || displayError}
            on:click={() => alertActionHandler()}
          >
            Confirm
          </button>
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  .loading-container {
    width: 100%;
  }
  .list-group-item{
    display: flex;
    align-items: center;
    column-gap: var(--cox2m-spacing-3-units);
  }
</style>
