<script>
  import LoadingSpinner from "../../LoadingSpinner.svelte";
  import Overlay from "../../Overlay.svelte";
  import Button from "../../Button.svelte";
  import Icon from "../../Icon.svelte";
  import axios from "axios";

  import { createEventDispatcher } from "svelte";
  import {
    getCookieByName,
    setErrorEventData,
    setSuccessToast,
  } from "../../../funcs";

  const dispatch = createEventDispatcher();

  export let open = false;
  export let alertToDelete = null;

  let loading = false;

  const headers = {
    headers: {
      Authorization: getCookieByName(`${"production"}_token`),
    },
  };

  const deleteConfirmation = async () => {
    loading = true;

    try {
      await axios.delete(
        `https://smartcities-api.cox2m.com/notifications/settings/${alertToDelete.id}`,
        headers
      );
      setSuccessToast(window.dispatchEvent, "Alert deleted successfully");
      dispatch("delete-success", alertToDelete.id);
      open = false;
    } catch (error) {
      setErrorEventData(
        window.dispatchEvent,
        error.response,
        "delete-notification-configuration"
      );
    }
    loading = false;
  };
</script>

{#if open}
  <Overlay on:click={() => (open = false)}>
    <div
      class="alert-delete-modal"
      id={"alert-delete-modal"}
      data-testid={"alert-delete-modal"}
    >
      <div class="title-container">
        <Icon
          icon="info"
          color="var(--cox2m-clr-brand-600)"
          size="var(--cox2m-spacing-6-units)"
        />
        <span>Confirm action</span>
      </div>
      {#if loading}
        <div class="loading-container">
          <LoadingSpinner />
        </div>
      {:else}
        <div class="information-container">
          <span>
            you are about to delete this alert, are you sure you want to proceed
            with this action?
          </span>
        </div>

        <div class="buttons-container">
          <Button
            id={alertToDelete.id + "-delete-cancel-button"}
            text="Cancel"
            backgroundColor="var(--cox2m-clr-neutral-white)"
            color="var(--cox2m-clr-brand-600)"
            justifyContent="between"
            borderColor="var(--cox2m-clr-brand-600)"
            maxWidth="90px"
            height="32px"
            iconSize="calc(var(--cox2m-spacing-4-units) +
            var(--cox2m-visual-corrector))"
            on:click={() => dispatch("close-modal")}
          />

          <Button
            id={alertToDelete.id + "-delete-confirm-button"}
            text="Confirm"
            backgroundColor="var(--cox2m-clr-brand-600)"
            justifyContent="between"
            maxWidth="100px"
            height="32px"
            iconSize="calc(var(--cox2m-spacing-4-units) +
            var(--cox2m-visual-corrector))"
            on:click={() => deleteConfirmation()}
          />
        </div>
      {/if}
    </div>
  </Overlay>
{/if}

<style>
  .alert-delete-modal {
    background-color: var(--cox2m-clr-neutral-white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    width: min(500px, 100%);
    max-height: 80vh;
    overflow: auto;
  }
  .title-container {
    display: flex;
    align-items: center;
    gap: var(--cox2m-spacing-2-units);
    color: var(--cox2m-clr-neutral-600);
    font-weight: var(--cox2m-fw-bold);
    border-bottom: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400);
    padding: var(--cox2m-spacing-5-units) var(--cox2m-spacing-6-units);
    font-size: var(--cox2m-fs-700);
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--cox2m-spacing-3-units);
    margin-bottom: var(--cox2m-spacing-8-units);
    padding-right: var(--cox2m-spacing-5-units);
  }

  .information-container {
    padding: var(--cox2m-spacing-5-units);
  }

  .loading-container {
    display: grid;
    place-content: center;
    height: 200px;
  }
</style>
