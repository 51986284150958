<script>
  import NotificationsButton from "./notifications/NotificationsButton.svelte";
  import UserActions from "../components/users/UserActions.svelte";
  import Icon from "../../components/Icon.svelte";

  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  export let notifications = [];
  export let user;
</script>

<header class="position-relative">
  <div class="header-container w-100 h-100 d-flex align-items-center">
    <div class="d-flex align-items-center justify-content-between w-100">
      <div on:click={() => dispatch('menuExpanderClicked')} class="icon-menu-container align-items-center cursor-pointer">
        <Icon icon="menu" color="#141414" size="var(--cox2m-spacing-6-units)"/>
      </div>
      <div class="d-flex align-items-center">
        <span on:click={() => location.assign("https://smartcities.cox2m.com/#/apps")} >
          <object class="cox2m-logo cursor-pointer" data="../../design-system/media/Cox2m-logo.svg" title="cox2m-logo" >
        </span>
        <slot />
      </div>
      <div class="d-flex align-items-center">
        <div class="d-flex justify-content-center align-items-center flex-grow-1">
          <NotificationsButton bind:notifications />
          <UserActions {user}/>
      </div>
    </div>
  </div>
</div>
</header>

<style>
  .header-container{
    padding-inline: var(--cox2m-spacing-12-units);
  }

  .icon-menu-container{
    display: none;
  }

  header {
    background-color: var(--cox2m-clr-neutral-white);
    height: var(--header-height);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    z-index: 2;
  }

  .cox2m-logo{
    height: var(--cox2m-spacing-8-units);
    width: auto;
  }

  @media (max-width: 767.98px) {
    .icon-menu-container{
      display: flex;
    }
    .header-container{
      padding-inline: var(--cox2m-spacing-6-units);
    }
    .cox2m-logo{
      position: relative;
      right: -10px;
    }
  }
</style>
