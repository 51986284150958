<script>
  import AlertDeleteConfirmationModal from './AlertDeleteConfirmationModal.svelte';
  import AlertActivationToggleModal from './AlertActivationToggleModal.svelte';
  import ActivateAlertColumn from './ActivateAlertColumn.svelte';
  import AlertSeverityColumn from './AlertSeverityColumn.svelte';
  import AlertCreationModal from './AlertCreationModal.svelte';
  import AlertActionsColumn from './AlertActionsColumn.svelte';
  import ToggleSwitch from '../../../forms/ToggleSwitch.svelte';
  import Button from '../../Button.svelte';
  import Table from '../../Table.svelte';
  import Icon from '../../Icon.svelte';

  import {ALERT_SEVERITY_INDICATORS} from '../../../constants';
  import {getCookieByName} from '../../../funcs';
  import {createEventDispatcher} from 'svelte';

  export let notification = null;
  export let userPhoneNumber = null;
  export let userHasDevices = false;

  const dispatch = createEventDispatcher();
  const token = getCookieByName(`${'production'}_token`);

  let isOpen = false;

  let isCreationModalOpen = false;

  let isActivationToggleModalOpen = false;
  let activationToggleModalData = {};

  let alertToDelete = null;
  let isDeleteModalOpen = false;
  let alertToEdit = null;

  let rows = [];

  let columns = [
    {
      label: 'Active',
      key: 'is_active',
      type: 'object',
      Component: ActivateAlertColumn,
      width: 60,
      notSortable: true
    },
    {label: 'Reference Alert Name', key: 'name', type: 'text'},
    {
      label: 'Alert Priority',
      key: 'severity',
      type: 'text',
      Component: AlertSeverityColumn
    },
    {label: 'Alerts send via', key: 'via', type: 'text'},
    {label: 'Frequency', key: 'frequency', type: 'text'},
    {label: 'Snooze', key: 'snooze', type: 'text'},
    {label: 'Threshold Attribute', key: 'threshold', type: 'text'},
    {
      label: '',
      key: '',
      width: 50,
      notSortable: true,
      Component: AlertActionsColumn
    }
  ];

  const getConditionLabel = operator => {
    switch (operator) {
      case '>':
        return 'greater than ';
      case '<':
        return 'lower than ';
      case '>=':
        return 'greater than or equal to ';
      case '<=':
        return 'lower than or equal to ';
      case '==':
        return 'equal to ';
    }
  };

  const buildRows = () => {
    if (notification && notification.subscriptions)
      rows = notification.subscriptions
        .filter(subscription => subscription.clientId === token.substring(0, 8))
        .map(subscription => {
          return {
            id: subscription.id,
            is_active: subscription.status === 'ACTIVE', //we need to create a handler that sets an status of active or inactive for the alerts,
            name: subscription.customName,
            severity: subscription.severity,
            frequency: subscription.frequency.toLowerCase(),
            snooze: subscription.delay / 60000 + ' minutes',
            via: subscription.via.join(', ').toLowerCase(),
            threshold:
              getConditionLabel(subscription.condition) + subscription.threshold
          };
        });
  };

  const showToggleSubscription = ({
    rowIndex,
    is_active,
    modify_all = false
  }) => {
    isActivationToggleModalOpen = true;
    activationToggleModalData = {
      subscriptions: modify_all ? rows : [rows[rowIndex]],
      is_active,
      modify_all
    };
  };

  $: buildRows(notification);
  $: allAlertsActivated = rows.every(row => row.is_active);
</script>

<style>
  .notification-card {
    width: 100%;

    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-300);
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 8px;
    padding: var(--cox2m-spacing-4-units) var(--cox2m-spacing-8-units);
    margin-bottom: var(--cox2m-spacing-3-units);
    color: var(--cox2m-clr-neutral-black);
  }
  .notification-data-container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: var(--cox2m-spacing-8-units);
    align-items: center;
  }
  p {
    margin: 0;
  }
  .notification-name-container {
    display: flex;
    align-items: center;
    gap: var(--cox2m-spacing-2-units);
    font-weight: var(--cox2m-fw-bold);
  }
  .notification-table-container {
    border-top: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-300);
    padding-top: var(--cox2m-spacing-2-units);
  }
  .no-alerts-section {
    background-color: var(--cox2m-clr-neutral-200);
    padding: var(--cox2m-spacing-6-units);
    color: var(--cox2m-clr-neutral-700);
    font-weight: var(--cox2m-fw-bold);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--cox2m-spacing-2-units);
    height: 200px;
  }
  .button-section-container {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--cox2m-spacing-3-units);
  }

  :global(.notification-card table thead) {
    z-index: 1;
  }
</style>

<AlertCreationModal
  bind:open={isCreationModalOpen}
  on:close-modal={() => {
    isCreationModalOpen = false;
    if (alertToEdit) {
      alertToEdit = null;
    }
  }}
  on:creation-success={() => dispatch('creation-success')}
  alertData={{phoneNumber: userPhoneNumber, notification: notification, userHasDevices: userHasDevices}}
  {alertToEdit} />

<AlertActivationToggleModal
  bind:open={isActivationToggleModalOpen}
  data={activationToggleModalData}
  on:close-modal={() => (isActivationToggleModalOpen = false)}
  on:toggle-status-success={e => (rows = rows.map(row => {
      if (e.detail.ids.includes(row.id)) {
        return {...row, is_active: e.detail.status};
      } else {
        return row;
      }
    }))} />

<AlertDeleteConfirmationModal
  bind:open={isDeleteModalOpen}
  {alertToDelete}
  on:close-modal={() => (isDeleteModalOpen = false)}
  on:delete-success={({detail}) => {
    rows = rows.filter(row => row.id !== detail);
  }} />

{#if notification}
  <div
    class="notification-card"
    id={notification.id + '-container'}
    data-testid={notification.id + '-container'}>
    <div class="notification-data-container">
      <!-- /* this has to be changed to a notification property I guess that other option would be to use a filter and check if all of them are activated/deactivated, depends on the behavior
        also, we need to use this with the onClick property with e.prevent_default(), that way we won't change the value and we'll be able to show the confirmation modal and act depending on the result of the confirmation */ -->
      <ToggleSwitch
        checked={allAlertsActivated}
        disabled={!notification.subscriptions.length}
        onClick={e => {
          e.preventDefault();
          showToggleSubscription({
            rowIndex: null,
            is_active: allAlertsActivated,
            modify_all: true
          });
        }} />
      <div
        class="notification-info"
        id={notification.id + '-info-container'}
        data-testid={notification.id + '-info-container'}>
        <div
          class="notification-name-container"
          id={notification.id + '-name-container'}
          data-testid={notification.id + '-name-container'}>
          <Icon
            size="var(--cox2m-spacing-5-units)"
            icon={notification.severity ? ALERT_SEVERITY_INDICATORS[notification.severity].icon : ALERT_SEVERITY_INDICATORS.warning.icon}
            color={notification.severity ? ALERT_SEVERITY_INDICATORS[notification.severity].iconColor : ALERT_SEVERITY_INDICATORS.warning.iconColor} />
          <p>{notification.name}</p>
        </div>
        <p>{notification.summary}</p>
      </div>
      <Icon
        size="var(--cox2m-spacing-5-units)"
        icon={isOpen ? 'chevron-up' : 'chevron-down'}
        id={notification.id + '-expander'}
        onClick={() => (isOpen = !isOpen)} />
    </div>
    {#if isOpen}
      <div
        class="notification-table-container"
        id={notification.id + '-table-container'}
        data-testid={notification.id + '-table-container'}>
        {#if !notification.subscriptions.length}
          <div
            class="no-alerts-section"
            id={notification.id + '-no-alerts-section'}
            data-testid={notification.id + '-no-alerts-section'}>
            <Icon
              id={notification.id + '-no-alerts-section-icon'}
              size="var(--cox2m-spacing-15-units)"
              icon="bell-slash"
              color="var(--cox2m-clr-neutral-500)" />
            <span
              id={notification.id + '-no-alerts-section-message'}
              data-testid={notification.id + '-no-alerts-section-message'}>
              There are no alerts for this event create one with the button
              bellow
            </span>
            <Button
              id={notification.id + '-add-alert-button'}
              text="Create new alert"
              backgroundColor="var(--cox2m-clr-brand-600)"
              iconColor="var(--cox2m-clr-neutral-white)"
              justifyContent="between"
              height="32px"
              maxWidth="166px"
              iconSize="calc(var(--cox2m-spacing-4-units) +
              var(--cox2m-visual-corrector))"
              on:click={() => (isCreationModalOpen = true)} />
          </div>
        {:else}
          <Table
            id={notification.id + '-subscriptions-table'}
            {columns}
            {rows}
            on:is-subscription-active={e => showToggleSubscription({
                rowIndex: e.detail.index,
                is_active: e.detail.is_active,
                modify_all: false
              })}
            on:delete-alert={({detail}) => {
              isDeleteModalOpen = true;
              alertToDelete = detail;
            }}
            on:edit-alert={({detail}) => {
              isCreationModalOpen = true;
              alertToEdit = notification.subscriptions[detail.index];
            }} />
          <div class="button-section-container">
            <Button
              id={notification.id + '-add-alert-button'}
              text="Create new alert"
              backgroundColor="var(--cox2m-clr-brand-600)"
              iconColor="var(--cox2m-clr-neutral-white)"
              justifyContent="between"
              height="32px"
              maxWidth="166px"
              iconSize="calc(var(--cox2m-spacing-4-units) +
              var(--cox2m-visual-corrector))"
              on:click={() => (isCreationModalOpen = true)} />
          </div>
        {/if}
      </div>
    {/if}
  </div>
{/if}
